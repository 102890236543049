import useUrlBuilder from '@appCore/hooks/routeTools/useUrlBuilder';

import { PATH_PATTERNS } from './shared/constants';

/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderService} urlBuilderService */
/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderOptions} urlBuilderOptions */

/**
 * @callback commitmentsDetailBuilder
 * @param {{ pathParams: { username: string, sport: string }, options?: urlBuilderOptions }} params
 * @returns {urlBuilderService}
 */

/**
 * @callback commitmentsListBuilder
 * @param {{ searchParams?: { athleteState?: string, athleticAssociation?: string, conference?: string, page?: string, query?: string, sportEnum?: string, teamState?: string, year?: string }, options?: urlBuilderOptions }} [params]
 * @returns {urlBuilderService}
 */

/**
 * @typedef {Object} commitmentsUrlBuilder
 * @property {commitmentsDetailBuilder} commitmentsDetail
 * @property {commitmentsListBuilder} commitmentsList
 */

/** */
const urlBuilderConfig = {
    commitmentsDetail: {
        reactRouterPathPattern: PATH_PATTERNS.commitmentsDetail
    },
    commitmentsList: {
        reactRouterPathPattern: PATH_PATTERNS.commitmentsList,
        searchParamKeys: [
            'athleteState',
            'athleticAssociation',
            'conference',
            'page',
            'query',
            'sportEnum',
            'teamState',
            'year'
        ]
    }
};

/**
 * @returns {commitmentsUrlBuilder}
 */
export const useCommitmentsUrlBuilder = () => useUrlBuilder(urlBuilderConfig);
