import { redirect } from 'react-router-dom';

const normalizeSearchParams = ({
    searchParamKeys,
    searchParams,
    normalizationFunc = ({ value }) => value?.toLowerCase()
}) => {
    return searchParamKeys.reduce(
        (acc, key) => {
            if (!Object.hasOwn(searchParams, key)) {
                return acc;
            }
            const currentValue = searchParams[key];
            const normalizedValue = normalizationFunc({ key, value: currentValue });
            acc.normalized[key] = normalizedValue;
            if (currentValue !== normalizedValue) {
                acc.isEquivalent = false;
            }
            return acc;
        },
        { normalized: { ...searchParams }, isEquivalent: true }
    );
};

/**
 * This function's primary purpose is to correct (normalize, via .toLowerCase()) search params to allow for
 * some amount of tolerance in malformed links. We're currently doing this on pages where we
 * know we have some older links "in the wild" that folks will still use to access the site,
 * and we don't want to break their experience. In general, we should only try to use this
 * where necessary.
 *
 * @param params
 * @param {string[]} params.searchParamKeys - An array of search param keys to normalize
 */
export const redirectWithNormalizedSearchParams = ({ searchParamKeys }) => ({ request }) => {
    const currentURL = new URL(request.url);
    const { normalized, isEquivalent } = normalizeSearchParams({
        searchParamKeys,
        searchParams: Object.fromEntries(currentURL.searchParams.entries())
    });
    if (isEquivalent) {
        return null;
    }
    const newURL = new URL(request.url);
    newURL.search = `?${new URLSearchParams(normalized).toString()}`;
    return redirect(newURL.href);
};
