import { useMemo } from 'react';
import { generateURLBuilderFactory, applyUrlBuilderFactoryToConfig } from '@appCore/utility/routingUtils';

import useRoutingService from './useRoutingService/useRoutingService';

/**
 * This hook is a simple wrapper for building 'useSomeModuleUrlBuilder()' hooks elsewhere
 */
const useUrlBuilder = config => {
    const routingService = useRoutingService();
    return useMemo(() => {
        const factory = generateURLBuilderFactory(routingService);
        return applyUrlBuilderFactoryToConfig({ config, factory });
    }, [routingService]);
};

export default useUrlBuilder;
