import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { reactRouterGetDerivedPath, sameDomainPathReload } from '@appCore/utility/routingUtils';

// Builds a routing service using React Router internals
const buildReactRouterRoutingService = rrNavigate => ({
    href: ({ reactRouterPathPattern, pathParams, searchParams }) => {
        if (!reactRouterPathPattern) {
            throw new Error(`You must provide a reactRouterPathPattern to use the ReactRouterRoutingService`);
        }
        return reactRouterGetDerivedPath({ reactRouterPathPattern, pathParams, searchParams });
    },
    go: ({
        reactRouterPathPattern,
        pathParams,
        searchParams,
        options = { reloadPage: false, location: undefined, preventScrollReset: false }
    }) => {
        if (!reactRouterPathPattern) {
            throw new Error(`You must provide a reactRouterPathPattern to use the ReactRouterRoutingService`);
        }
        const path = reactRouterGetDerivedPath({ reactRouterPathPattern, pathParams, searchParams });

        if (options.reloadPage) {
            sameDomainPathReload({ newPath: path, replace: options.location === 'replace' });
            return;
        }

        const derivedOptions = {
            replace: options.location === 'replace',
            preventScrollReset: options.preventScrollReset
        };

        rrNavigate(path, derivedOptions);
    }
});

const useReactRouterRoutingService = () => {
    const rrNavigate = useNavigate();
    return useMemo(() => buildReactRouterRoutingService(rrNavigate), [rrNavigate]);
};

export default useReactRouterRoutingService;
