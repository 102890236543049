export const createSimplePubSub = () => {
    const _subscriptions = new Set();

    return {
        subscribe: callback => {
            _subscriptions.add(callback);
            return () => {
                _subscriptions.delete(callback);
            };
        },
        publish: data => Array.from(_subscriptions).forEach(item => item(data))
    };
};
