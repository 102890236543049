/* NOTE: This file is currently copied from FieldLevelHome with slight modifications. Any changes should be done in sync */
import React from 'react';
import cx from 'classnames';

import WiredLink from '@appCore/components/WiredLink';

export const NavLink = ({ href, onDark, children, linkProps }) => {
    return (
        <li className={cx('inline-block text-[12px] font-bold uppercase', onDark && 'text-on-dark')}>
            {linkProps ? (
                <WiredLink {...linkProps} unstyled>
                    <span className="py-4 px-6">{children}</span>
                </WiredLink>
            ) : (
                <a className="no-underline hover:no-underline py-4 px-6" href={href}>
                    {children}
                </a>
            )}
        </li>
    );
};

export const NavButton = ({ href, onDark, children }) => {
    return (
        <li className={cx('inline-block text-[12px] cursor-pointer')}>
            <a className="no-underline hover:no-underline" href={href}>
                <button
                    className={cx(
                        'uppercase border rounded-sm font-bold py-2 px-4',
                        onDark ? 'border-base text-on-dark' : 'border-black'
                    )}
                >
                    {children}
                </button>
            </a>
        </li>
    );
};

export const Heading = ({ as: Element = 'h3', children }) => {
    return <Element className="leading-tight text-[18px] font-[600]">{children}</Element>;
};

export const ExternalLink = ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
    </a>
);
