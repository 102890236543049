import { useLinkClickHandler } from 'react-router-dom';

const useReactRouterWiredLink = props => {
    const { reloadPage, preventScrollReset, ...linkProps } = props;

    const { onClick, url, target } = linkProps;

    const handleClick = useLinkClickHandler(url, { target, preventScrollReset });

    const localOnClick = e => {
        if (onClick) {
            onClick(e);
        }
        if (!e.defaultPrevented) {
            handleClick(e);
        }
    };

    return { ...linkProps, onClick: reloadPage ? onClick : localOnClick };
};

export default useReactRouterWiredLink;
