import useUrlBuilder from '@appCore/hooks/routeTools/useUrlBuilder';

import { PATH_PATTERNS } from './shared/constants';

/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderService} urlBuilderService */
/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderOptions} urlBuilderOptions */

/**
 * @callback eventsDetailBuilder
 * @param {{ pathParams: { id: string }, options?: urlBuilderOptions }} params
 * @returns {urlBuilderService}
 */

/**
 * @callback eventsListBuilder
 * @param {{ searchParams?: { sportEnum?: string, state?: string, age?: string, date?: string, level?: string, eventType?: string, price?: string, query?: string, page?: string }, options?: urlBuilderOptions }} [params]
 * @returns {urlBuilderService}
 */

/**
 * @typedef {Object} eventsUrlBuilder
 * @property {eventsDetailBuilder} eventsDetail
 * @property {eventsListBuilder} eventsList
 */

/** */
const urlBuilderConfig = {
    eventsDetail: {
        reactRouterPathPattern: PATH_PATTERNS.eventsDetail
    },
    eventsList: {
        reactRouterPathPattern: PATH_PATTERNS.eventsList,
        searchParamKeys: ['sportEnum', 'state', 'age', 'date', 'level', 'eventType', 'price', 'query', 'page']
    }
};

/**
 * @returns {eventsUrlBuilder}
 */
export const useEventsUrlBuilder = () => useUrlBuilder(urlBuilderConfig);
