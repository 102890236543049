import React, { useState, useContext, useEffect } from 'react';
import { UNSAFE_useScrollRestoration, useMatch, useLocation, matchPath } from 'react-router-dom';

import { PATH_PATTERNS as COMMITMENTS_PATH_PATTERNS } from '@appPublic/routes/commitments/shared/constants';
import { PATH_PATTERNS as TEAMS_PATH_PATTERNS } from '@appPublic/routes/teams/shared/constants';

const scrollRestorationContext = React.createContext(null);
const useScrollRestorationCtx = () => useContext(scrollRestorationContext);

export const ScrollRestorationSignalProvider = props => {
    const [signalIsMounted, setSignalIsMounted] = useState(false);

    return (
        <scrollRestorationContext.Provider value={{ signalIsMounted, setSignalIsMounted }}>
            {props.children}
        </scrollRestorationContext.Provider>
    );
};

export const ScrollRestorationSignal = props => {
    const { setSignalIsMounted } = useScrollRestorationCtx();

    useEffect(() => {
        setSignalIsMounted(true);
        return () => setSignalIsMounted(false);
    }, []);

    return null;
};

/**
 * UNSTABLE_ScrollRestoration uses a patched version of react-router-dom's "useScrollRestoration" utility which allows us
 * to provide a "signal" for when we'd like ScrollRestoration to activate. Without this feature in place, when users
 * navigate away from the site and then use their back-button to return, ScrollRestoration is inconsistent because
 * it fires too soon (e.g before data has loaded and rendering is complete).
 *
 * In its initial implementation, we're only selectively applying this for certain routes that have known "cross-app"
 * links (the team profile pages in particular), but it could be extended to the entire app if we find it's useful
 * and easy enough to maintain.
 */
const UNSTABLE_ScrollRestoration = () => {
    const isSignaledRoute = [COMMITMENTS_PATH_PATTERNS.commitmentsList, TEAMS_PATH_PATTERNS.teamsList].reduce(
        (acc, path) => {
            // Since useMatch is a hook, we need to ensure that we call it for every route, even if we already
            // know that we've matched. This could potentially be optimized by using 'matchPath' and/or useLocation, but
            // given the size of the list, there shouldn't be much of a difference.
            const isMatch = useMatch(path);
            if (acc) {
                return acc;
            }
            return isMatch;
        },
        false
    );
    const { signalIsMounted } = useScrollRestorationCtx();
    UNSAFE_useScrollRestoration({ signal: isSignaledRoute ? signalIsMounted : null });
    return null;
};

export default UNSTABLE_ScrollRestoration;
