import useUrlBuilder from '@appCore/hooks/routeTools/useUrlBuilder';

import { PATH_PATTERNS } from './shared/constants';

/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderService} urlBuilderService */
/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderOptions} urlBuilderOptions */

/**
 * @callback jobOpeningsDetailBuilder
 * @param {{ pathParams: { id: string }, options?: urlBuilderOptions }} params
 * @returns {urlBuilderService}
 */

/**
 * @callback jobOpeningsListBuilder
 * @param {{ searchParams?: { sportEnum?: string, state?: string, level?: string, position?: string, page?: string }, options?: urlBuilderOptions }} [params]
 * @returns {urlBuilderService}
 */

/**
 * @typedef {Object} jobOpeningsUrlBuilder
 * @property {jobOpeningsDetailBuilder} jobOpeningsDetail
 * @property {jobOpeningsListBuilder} jobOpeningsList
 */

/** */
const urlBuilderConfig = {
    jobOpeningsDetail: {
        reactRouterPathPattern: PATH_PATTERNS.jobOpeningsDetail
    },
    jobOpeningsList: {
        reactRouterPathPattern: PATH_PATTERNS.jobOpeningsList,
        searchParamKeys: ['sportEnum', 'state', 'level', 'position', 'page']
    }
};

/**
 * @returns {jobOpeningsUrlBuilder}
 */
export const useJobOpeningsUrlBuilder = () => useUrlBuilder(urlBuilderConfig);
