import React, { useMemo } from 'react';

import useRouteTools from '../useRouteTools/useRouteTools';

const useRoutingService = () => {
    const routeTools = useRouteTools();
    return useMemo(() => routeTools.routingService, [routeTools.routingService]);
};

export default useRoutingService;
