/* NOTE: This file is currently copied from FieldLevelHome with slight modifications. Any changes should be done in sync */
import React, { useRef, useContext } from 'react';
import cx from 'classnames';

import { NavLink, NavButton } from './Common';
import MobileMenu from './MobileMenu';
import styles from './publicNav.module.css';

import { isInsideNativeApp } from '@appCore/utility/nativeBridge';
import { useCrossAppAnchorTargetAssignment, NavLinksContext, getReturnUrl } from './utility';

const logo = '/assets/v5/img/logo/fl-logo-horz-blk@2x.png';

const MainNavSection = ({ children }) => {
    return <div className="w-full flex justify-center bg-foreground-base shadow-sm h-20">{children}</div>;
};

const Brand = () => {
    return isInsideNativeApp() ? (
        <span className="px-6 md:pl-8">
            <img src={logo} alt="FieldLevel" width="184" height="23" />
        </span>
    ) : (
        <a href="/" className="px-6 md:pl-8">
            <img src={logo} alt="FieldLevel" width="184" height="23" />
        </a>
    );
};

export default function TopNav() {
    const navRef = useRef(null);
    useCrossAppAnchorTargetAssignment(navRef);
    const linkProps = useContext(NavLinksContext);

    return (
        <nav ref={navRef} className={cx('fixed top-0 w-full z-10', styles.TopNav)}>
            <MainNavSection>
                <div className="flex items-center w-full h-full relative md:max-w-[1440px]">
                    <div className="flex flex-none items-center h-full">
                        <Brand />
                    </div>

                    <ul className="hidden flex-1 items-center justify-start list-none my-0 lg:flex">
                        <NavLink href="https://recruiting.fieldlevel.com">Guidance</NavLink>
                        <NavLink href="/app/teams" linkProps={linkProps.teamsLinkProps}>
                            Teams
                        </NavLink>
                        <NavLink href="/app/commitments" linkProps={linkProps.commitmentsLinkProps}>
                            Commitments
                        </NavLink>
                        <NavLink href="/app/account/plans/athletes" linkProps={linkProps.accountPlansLinkProps}>
                            Pricing
                        </NavLink>
                    </ul>

                    <div className="flex flex-auto items-center justify-end lg:flex-none">
                        <ul className="hidden sm:block">
                            <NavLink href={`/signin?ReturnUrl=${getReturnUrl()}`}>Log In</NavLink>
                            <li className="inline-block w-4" />
                        </ul>
                        <ul className="hidden xs:block">
                            <NavButton href="/join">Sign Up</NavButton>
                            <li className="inline-block w-4" />
                        </ul>
                        <div className="block lg:hidden">
                            <MobileMenu />
                        </div>
                    </div>
                </div>
            </MainNavSection>
        </nav>
    );
}
