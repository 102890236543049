const deprecatedMessages = [];

export const generateDeprecationNotice = ({ message }) => {
    // only output deprecation warnings once per page load to avoid spamming the console
    if (deprecatedMessages.includes(message)) return;

    if (process.env.NODE_ENV !== 'production') {
        console.warn(`[@fieldlevel/deprecated] ${message}`);
        deprecatedMessages.push(message);
    }
};

export const throwDeprecationError = ({ message }) => {
    throw new Error(`[@fieldlevel/deprecated] ${message}`);
};
