// These are magic strings that are used to communicate to the native app
export const MESSAGE_TYPE = {
    STATE_START: 'state-change-start',
    STATE_SUCCESS: 'state-change-success',
    OPEN_SHARE: 'open-share-sheet',
    OPEN_REVIEW: 'open-review-prompt',
    PERMISSIONS: 'request-permissions'
};

export const OS = {
    ios: 'ios',
    android: 'android',
    windowsPhone: 'windowsPhone',
    unknown: 'unknown'
};

const bridge = window.ReactNativeWebView;
const oldBridge =
    window.webkit &&
    window.webkit.messageHandlers &&
    (window.webkit.messageHandlers.reactNative || window.webkit.messageHandlers.ReactNative);

const isMessageAccepted = messageType => {
    return window?.FLNativeApp?.acceptedMessages?.includes(messageType);
};

export const postNativeMessage = message => {
    if (bridge) {
        const messageString = JSON.stringify(message);
        bridge.postMessage(messageString);
    } else if (oldBridge) {
        oldBridge.postMessage(message);
    }
};

export const isInsideNativeApp = () => {
    const hasUserAgent = navigator.userAgent?.toLowerCase()?.includes('fieldlevel');
    return hasUserAgent || bridge || oldBridge;
};

export const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return OS.windowsPhone;
    }

    if (/android/i.test(userAgent)) {
        return OS.android;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
        return OS.ios;
    }

    return OS.unknown;
};

export const openShareSheet = (message, subject, category) => {
    const shareMessage = {
        type: MESSAGE_TYPE.OPEN_SHARE,
        message: message,
        subject: subject,
        category: category
    };

    if (isMessageAccepted(MESSAGE_TYPE.OPEN_SHARE)) {
        postNativeMessage(shareMessage);
        return true;
    } else {
        return false;
    }
};

export const openReviewPrompt = () => {
    if (isMessageAccepted(MESSAGE_TYPE.OPEN_REVIEW)) {
        postNativeMessage({ type: MESSAGE_TYPE.OPEN_REVIEW });
        return true;
    } else {
        return false;
    }
};

export const checkPermissions = permission => {
    const message = {
        type: MESSAGE_TYPE.PERMISSIONS,
        action: 'check',
        permission: permission
    };
    postNativeMessage(message);
};
