import React from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

/**
 * Instantiate the Query Client. Here we can apply helpful default settings, like how many retries each request
 * should make before being considered an error, or what the default cache expiration should be.
 *
 */
export const rqClient = new QueryClient({ defaultOptions: { queries: { retry: 1, refetchOnWindowFocus: false } } });

/**
 * This is just a wrapper for the React Query client context. The client itself is a singleton that's created
 * only once on app start. So despite the providers getting destroyed/re-built on each route change, all of the
 * fetching/caching data still remains intact, for use around the app.
 *
 */
const ReactQueryProvider = props => {
    return <QueryClientProvider client={rqClient}>{props.children}</QueryClientProvider>;
};

export default ReactQueryProvider;
