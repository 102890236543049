import React from 'react';

import TopNav from '@appCore/components/navigation/public/TopNav';
import Footer from '@appCore/components/navigation/public/Footer';

import { useAccountUrlBuilder } from '@appPublic/routes/account/plans/urlBuilder';
import { useCommitmentsUrlBuilder } from '@appPublic/routes/commitments/urlBuilder';
import { useTeamsUrlBuilder } from '@appPublic/routes/teams/urlBuilder';
import { useEventsUrlBuilder } from '@appPublic/routes/events/urlBuilder';
import { useJobOpeningsUrlBuilder } from '@appPublic/routes/job-openings/urlBuilder';
import { useRecruitingNeedsUrlBuilder } from '@appPublic/routes/recruiting-needs/urlBuilder';

import { NavLinksContext } from '@appCore/components/navigation/public/utility';
import LayoutErrorBoundary from './LayoutErrorBoundary';

const Layout = ({ children }) => {
    const accountUrlBuilder = useAccountUrlBuilder();
    const commitmentsUrlBuilder = useCommitmentsUrlBuilder();
    const teamsUrlBuilder = useTeamsUrlBuilder();
    const eventsUrlBuilder = useEventsUrlBuilder();
    const jobOpeningsUrlBuilder = useJobOpeningsUrlBuilder();
    const recruitingNeedsUrlBuilder = useRecruitingNeedsUrlBuilder();

    const linkProps = {
        accountPlansLinkProps: accountUrlBuilder.accountPlansAthletes().wiredLinkProps,
        commitmentsLinkProps: commitmentsUrlBuilder.commitmentsList().wiredLinkProps,
        teamsLinkProps: teamsUrlBuilder.teamsList().wiredLinkProps,
        eventsLinkProps: eventsUrlBuilder.eventsList().wiredLinkProps,
        jobOpeningsLinkProps: jobOpeningsUrlBuilder.jobOpeningsList().wiredLinkProps,
        recruitingNeedsLinkProps: recruitingNeedsUrlBuilder.recruitingNeedsList().wiredLinkProps
    };

    return (
        <NavLinksContext.Provider value={linkProps}>
            <TopNav />
            <div className="min-h-screen">
                <LayoutErrorBoundary>{children}</LayoutErrorBoundary>
            </div>
            <Footer />
        </NavLinksContext.Provider>
    );
};

export default Layout;
