import React from 'react';

const FLOnlyLogo = props => {
    return (
        <svg height="100%" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_1502_8711)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.343811 2.2125L2.56987 4.1618L5.3609 3.19444L4.47241 2.41666L2.79682 2.99514L1.46408 1.82361L0.343811 2.2125Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.81044 0.666626L2.01941 1.63399L2.91273 2.41177L5.70376 1.4444L4.81044 0.666626Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.01892 4.55551L3.91224 5.33329L5.02769 4.9444L4.13919 4.16663L3.01892 4.55551Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_1502_8711">
                    <rect width="6" height="6" rx="3" fill="currentColor" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FLOnlyLogo;
