export const PATH_PATTERNS = {
    login_oneTimeCode: '/app/login/one-time-code'
};

export const USER_PERSONA_ENUM = {
    Unknown: 0,
    Alumni: 1,
    Athlete: 2,
    Coach: 3,
    FamilyMember: 4,
    Lead: 5
};

export const COMMUNICATION_METHOD_TYPE_ENUM = {
    EmailPrimary: 0,
    EmailSecondary: 1,
    WebAddress: 2,
    PhoneMobile: 3,
    PhoneHome: 4,
    PhoneWork: 5,
    Fax: 6,
    WebFacebook: 7,
    WebTwitter: 8
};

export const ONE_TIME_LOGIN_CODE_CATEGORY = 'OneTimeLoginCategory';
export const ONE_TIME_LOGIN_CODE_REQUEST_CODE = 'RequestCode';
export const ONE_TIME_LOGIN_CODE_CREATE_ACCOUNT_PROMPT = 'CreateAccountPrompt';
