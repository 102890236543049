import { useParams, useSearchParams } from 'react-router-dom';

const useReactRouterURLParams = () => {
    const searchParams = [...useSearchParams()[0].entries()].reduce((acc, [key, value]) => {
        if (Object.hasOwn(acc, key)) {
            if (Array.isArray(acc[key])) {
                acc[key].push(value);
            } else {
                acc[key] = [acc[key], value];
            }
        } else {
            acc[key] = value;
        }
        return acc;
    }, {});
    const pathParams = useParams();

    return {
        path: pathParams,
        search: searchParams
    };
};

export default useReactRouterURLParams;
