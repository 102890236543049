/* NOTE: This file is currently copied from FieldLevelHome with slight modifications. Any changes should be done in sync */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { animated, useSpring } from 'react-spring';
import { isInsideNativeApp } from '@appCore/utility/nativeBridge';

import WiredLink from '@appCore/components/WiredLink';

import { NavLinksContext, getReturnUrl } from './utility';
import { Heading, NavLink, NavButton } from './Common';

const darkColor = 'hsl(0, 0%, 80%)';
const lightColor = 'hsl(0, 0%, 20%)';

const TopBar = ({ open }) => {
    const { d, color } = useSpring({
        d: open ? 'M 3 16.5 L 17 2.5' : 'M 2 2.5 L 20 2.5',
        color: open ? darkColor : lightColor
    });
    return <animated.path d={d} stroke={color} />;
};

const MiddleBar = ({ open }) => {
    const { opacity, color } = useSpring({
        opacity: open ? 0 : 1,
        color: open ? darkColor : lightColor
    });
    return <animated.path d="M 2 9.423 L 20 9.423" stroke={color} opacity={opacity} />;
};

const BottomBar = ({ open }) => {
    const { d, color } = useSpring({
        d: open ? 'M 3 2.5 L 17 16.346' : 'M 2 16.346 L 20 16.346',
        color: open ? darkColor : lightColor
    });
    return <animated.path d={d} stroke={color} />;
};

const ToggleButton = ({ open, onClick }) => {
    return (
        <button
            className="flex-none relative w-11 h-11 p-0 cursor-pointer bg-transparent"
            aria-label="Toggle menu"
            onClick={onClick}
        >
            <animated.svg className="absolute top-3 right-3" width="23" height="23" viewBox="0 0 23 23">
                <TopBar open={open} />
                <MiddleBar open={open} />
                <BottomBar open={open} />
            </animated.svg>
        </button>
    );
};

const MobileNavToggle = ({ children }) => {
    return (
        <div className="flex flex-none items-center justify-end h-20 pr-3">
            <div className="flex items-center flex-none z-10">{children}</div>
        </div>
    );
};

const MobileNavLink = ({ href, onClick, children, linkProps }) => {
    return linkProps ? (
        <WiredLink {...linkProps} unstyled>
            <span className="block py-2">{children}</span>
        </WiredLink>
    ) : (
        <a className="py-2 no-underline hover:no-underline" href={href} onClick={onClick}>
            {children}
        </a>
    );
};

export default function MobileMenu() {
    const [open, setOpen] = useState(false);
    const sidebarRef = useRef(null);
    const linkProps = useContext(NavLinksContext);

    const { transform } = useSpring({
        transform: `translate3d(${open ? '0%' : '100%'},0,0)`
    });

    const onToggle = () => {
        setOpen(open => !open);
    };

    const closeMenu = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (sidebarRef.current == null) return;

        if (open) {
            disableBodyScroll(sidebarRef.current);
        } else {
            enableBodyScroll(sidebarRef.current);
        }
    }, [open]);

    const teamsLinkProps = linkProps.teamsLinkProps ? { ...linkProps.teamsLinkProps, onClick: closeMenu } : null;
    const commitmentsLinkProps = linkProps.commitmentsLinkProps
        ? { ...linkProps.commitmentsLinkProps, onClick: closeMenu }
        : null;
    const jobOpeningsLinkProps = linkProps.jobOpeningsLinkProps
        ? { ...linkProps.jobOpeningsLinkProps, onClick: closeMenu }
        : null;
    const recruitingNeedsLinkProps = linkProps.recruitingNeedsLinkProps
        ? { ...linkProps.recruitingNeedsLinkProps, onClick: closeMenu }
        : null;
    const eventsLinkProps = linkProps.eventsLinkProps ? { ...linkProps.eventsLinkProps, onClick: closeMenu } : null;

    return (
        <>
            <animated.ul
                className="flex items-center gap-4 fixed p-4 top-0 left-0 w-full h-20 z-10 bg-background-black"
                style={{ transform }}
            >
                <NavLink href={`/signin?ReturnUrl=${getReturnUrl()}`} onDark>
                    Log in
                </NavLink>
                <NavButton href="/join" onDark>
                    Sign Up
                </NavButton>
            </animated.ul>
            <animated.div
                ref={sidebarRef}
                className="fixed w-full h-screen right-0 top-0 bg-foreground-base overflow-y-auto z-[1]"
                style={{ transform }}
            >
                <div className="flex h-auto flex-col py-28 px-8 overflow-y-scroll">
                    <Heading>Explore</Heading>
                    <div className="h-4" />
                    {!isInsideNativeApp() && (
                        <MobileNavLink href="https://recruiting.fieldlevel.com">Guidance</MobileNavLink>
                    )}
                    <MobileNavLink href="/app/teams" onClick={closeMenu} linkProps={teamsLinkProps}>
                        Teams
                    </MobileNavLink>
                    <MobileNavLink href="/app/commitments" onClick={closeMenu} linkProps={commitmentsLinkProps}>
                        Commitments
                    </MobileNavLink>
                    <MobileNavLink href="/app/job-openings" onClick={closeMenu} linkProps={jobOpeningsLinkProps}>
                        Coaching Jobs
                    </MobileNavLink>
                    <MobileNavLink
                        href="/app/recruiting-needs"
                        onClick={closeMenu}
                        linkProps={recruitingNeedsLinkProps}
                    >
                        Recruiting Needs
                    </MobileNavLink>
                    <MobileNavLink href="/app/events" onClick={closeMenu} linkProps={eventsLinkProps}>
                        Events
                    </MobileNavLink>
                    {!isInsideNativeApp() && (
                        <MobileNavLink href="/app/account/plans/athletes" onClick={closeMenu}>
                            Pricing
                        </MobileNavLink>
                    )}
                    <div className="h-8" />
                    {!isInsideNativeApp() && (
                        <>
                            <Heading>Company</Heading>
                            <div className="h-4" />
                            <MobileNavLink href="/about/company">About</MobileNavLink>
                            <MobileNavLink href="/about/press">Press</MobileNavLink>
                            <MobileNavLink href="/about/jobs">Jobs</MobileNavLink>
                            <MobileNavLink href="/contact">Contact</MobileNavLink>
                            <MobileNavLink href="/policies/terms">Legal</MobileNavLink>
                            <div className="h-8" />
                        </>
                    )}
                    <Heading>Account</Heading>
                    <div className="h-4" />
                    <MobileNavLink href={`/signin?ReturnUrl=${getReturnUrl()}`}>Log In</MobileNavLink>
                    <MobileNavLink href="/join">Sign Up</MobileNavLink>
                </div>
            </animated.div>
            <MobileNavToggle>
                <ToggleButton open={open} onClick={onToggle} />
            </MobileNavToggle>
        </>
    );
}
