import { useLayoutEffect, createContext } from 'react';

export const NavLinksContext = createContext({});

const crossAppHrefs = [
    '/',
    '/signin',
    '/join',
    '/about/company',
    '/about/press',
    '/about/jobs',
    '/contact',
    '/policies/terms',
    '/app/teams',
    '/app/commitments',
    '/app/events',
    '/app/job-openings',
    '/app/recruiting-needs'
];

const addTargetToAnchorIfNecessary = anchorEl => {
    const href = anchorEl?.getAttribute('href');
    if (crossAppHrefs.includes(href)) {
        anchorEl.target = '_self';
    }
};

// This hook applies a layout effect that modifies all child anchor tags of the passed element ref
// in order to add a necessary target="_self" to a list of known cross-app locations. This prevents
// ui-router from assuming these links are in-app navigations and instead forces a full page navigation.
// Since this is only necessary in the Angular app, this hook no-ops if Angular is not present
export const useCrossAppAnchorTargetAssignment = parentRef => {
    useLayoutEffect(() => {
        if (!window.angular) return;

        const parentEl = parentRef.current;
        if (parentRef) {
            const anchorEls = parentEl.getElementsByTagName('a');
            const anchorsArray = Array.from(anchorEls);
            anchorsArray.forEach(addTargetToAnchorIfNecessary);
        }
    });
};

export const getReturnUrl = () => encodeURIComponent(`${window.location.pathname}${window.location.search}`);
