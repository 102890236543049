import React, { useMemo } from 'react';

import useReactRouterRoutingService from '../useRoutingService/useReactRouterRoutingService';
import useReactRouterURLParams from '../useURLParams/useReactRouterURLParams';
import useReactRouterWiredLink from '../useWiredLink/useReactRouterWiredLink';

import routeToolsContext from './routeToolsContext';

const ReactRouterRouteToolsProvider = props => {
    const routingService = useReactRouterRoutingService();
    const urlParams = useReactRouterURLParams();

    const routeTools = useMemo(
        () => ({
            routingService,
            urlParams,
            useWiredLink: useReactRouterWiredLink
        }),
        [routingService, urlParams]
    );

    return <routeToolsContext.Provider value={routeTools}>{props.children}</routeToolsContext.Provider>;
};

export default ReactRouterRouteToolsProvider;
