import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@fieldlevel/playbook';

import useWiredLink from '@appCore/hooks/routeTools/useWiredLink/useWiredLink';
import { throwDeprecationError } from '@appCore/utility/consoleUtils';

const WiredLink = React.memo(
    React.forwardRef((props, ref) => {
        if (props.external) {
            throwDeprecationError({
                message: `Usage of props.external has been deprecated in Playbook/Link and is explicitly disallowed in WiredLink. Pass target="_blank" instead.`
            });
        }

        const linkProps = useWiredLink(props);

        return <Link {...linkProps} ref={ref} />;
    })
);

WiredLink.defaultProps = {
    reloadPage: false,
    preventScrollReset: false
};

export const WiredLinkPropTypes = {
    // Note: manually copying Link.propTypes until inferred types can be exported from Playbook
    // ...Link.propTypes,
    url: PropTypes.string,
    target: PropTypes.string,
    onClick: PropTypes.func,
    unstyled: PropTypes.bool,
    reloadPage: PropTypes.bool,
    preventScrollReset: PropTypes.bool
};

WiredLink.propTypes = WiredLinkPropTypes;
export default WiredLink;
