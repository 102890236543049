import useUrlBuilder from '@appCore/hooks/routeTools/useUrlBuilder';

import { PATH_PATTERNS } from './shared/constants';

/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderService} urlBuilderService */
/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderOptions} urlBuilderOptions */

/**
 * @callback accountPlansAthletesBuilder
 * @param {{ options?: urlBuilderOptions }} [params]
 * @returns {urlBuilderService}
 */

/**
 * @callback accountPlansTeamsBuilder
 * @param {{ options?: urlBuilderOptions }} [params]
 * @returns {urlBuilderService}
 */

/**
 * @typedef {Object} accountUrlBuilder
 * @property {accountPlansAthletesBuilder} accountPlansAthletes
 * @property {accountPlansTeamsBuilder} accountPlansTeams
 */

/** */
const urlBuilderConfig = {
    accountPlansAthletes: {
        reactRouterPathPattern: PATH_PATTERNS.account_plans_athletes
    },
    accountPlansTeams: {
        reactRouterPathPattern: PATH_PATTERNS.account_plans_teams
    }
};

/**
 * @returns {accountUrlBuilder}
 */
export const useAccountUrlBuilder = () => useUrlBuilder(urlBuilderConfig);
