import React from 'react';
import { useRouteError } from 'react-router-dom';

import Layout from '@appPublic/shared/components/layouts/Layout';

const logoSrc = '/assets/v5/img/logo/fl-logo-horz-blk@2x.png';

class RootErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Report the error somewhere?
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="absolute top-0 left-0 w-full h-screen p-8 text-center bg-[#FFF]">
                    <div className="flex items-center justify-center mb-8">
                        <img className="w-[250px] sm:w-[320px]" src={logoSrc} alt="Fieldlevel logo" />;
                    </div>
                    <h1 className="text-[28px] sm:text-[32px]">We're sorry, something went wrong.</h1>
                    <p className="mt-8 text-[16px]">Our team has been notified and is working toward a resolution.</p>
                </div>
            );
        }

        return this.props.children;
    }
}

const ErrorPropagator = () => {
    const error = useRouteError();
    throw error;
    return null;
};

/**
 * In general, we want to handle errors through UI established in the Layout component for the app. In order to
 * handle "route level" errors in there as well, we use React Router's tooling for retrieval and then manually
 * re-throw those errors inside the layout. Lastly, in case there are errors inside the Layout itself we include
 * one final fallback error boundary with simple html/tailwind css.
 */
const RootErrorElement = props => (
    <RootErrorBoundary>
        <Layout>
            <ErrorPropagator />
        </Layout>
    </RootErrorBoundary>
);

export default RootErrorElement;
