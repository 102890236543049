import { FIELD_LEVEL_WINDOW_KEY, SYSTEM_CONSTANTS_WINDOW_KEY } from './constants';

// Produces a C# DateTime parseable UTC string from the given date
export const getUtcTimestamp = date => {
    const isoString = date.toISOString();
    return isoString.replace(/Z/, '');
};

// Helper to get a UTC timestamp for the current time
export const getCurrentUtcTimestamp = () => {
    const now = new Date();
    return getUtcTimestamp(now);
};

export const getFieldLevelUtilityFromWindow = utilityKey => {
    return window[FIELD_LEVEL_WINDOW_KEY][utilityKey];
};

export const getSystemConstantFromWindow = constantKey =>
    getFieldLevelUtilityFromWindow(SYSTEM_CONSTANTS_WINDOW_KEY)[constantKey];
