/* NOTE: This file is currently copied from FieldLevelHome with slight modifications. Any changes should be done in sync */
import React, { useRef, useContext } from 'react';
import cx from 'classnames';

import { isInsideNativeApp } from '@appCore/utility/nativeBridge';
import WiredLink from '@appCore/components/WiredLink';
import TwitterMajor from '@appCore/icons/TwitterMajor';
import InstagramMajor from '@appCore/icons/InstagramMajor';
import FacebookMajor from '@appCore/icons/FacebookMajor';
import LinkedinMajor from '@appCore/icons/LinkedinMajor';

const logo = '/assets/v5/img/logo/fl-logo-horz-white@2x.png';

const appleLogo1xPng = '/assets/v5/img/apple-app-store-badge@1x.png';
const appleLogo2xPng = '/assets/v5/img/apple-app-store-badge@2x.png';
const appleLogo1xWebp = '/assets/v5/img/apple-app-store-badge@1x.webp';
const appleLogo2xWebp = '/assets/v5/img/apple-app-store-badge@2x.webp';

const googleLogo1xPng = '/assets/v5/img/google-play-badge@1x.png';
const googleLogo2xPng = '/assets/v5/img/google-play-badge@2x.png';
const googleLogo1xWebp = '/assets/v5/img/google-play-badge@1x.webp';
const googleLogo2xWebp = '/assets/v5/img/google-play-badge@2x.webp';

const buildSrcSet = (path1x, path2x) => [`${path1x} 1x`, `${path2x} 2x`].join(', ');

import { ExternalLink } from './Common';
import styles from './publicNav.module.css';

import { useCrossAppAnchorTargetAssignment, NavLinksContext, getReturnUrl } from './utility';

const NavColumn = ({ children }) => {
    return <div className="min-w-[180px] mb-8 lg:ml-4 lg:w-auto xl:w-auto">{children}</div>;
};

const NavHeader = ({ children }) => {
    return <div className="text-[18px] font-[700] block p-2 pt-0">{children}</div>;
};

const NavItem = ({ href, children, linkProps }) => {
    return linkProps ? (
        <WiredLink {...linkProps} unstyled>
            <span className="block p-2">{children}</span>
        </WiredLink>
    ) : (
        <a href={href} className="block p-2 cursor-pointer no-underline hover:no-underline active:no-underline">
            {children}
        </a>
    );
};

// Leaving this here for when we're hiring again!
// const HiringChip = () => {
//     return (
//         <span className="bg-foreground-base text-black text-[10px] font-[600] whitespace-nowrap rounded-sm px-[6px] py-[3px] ml-2">
//             WE&apos;RE HIRING
//         </span>
//     );
// };

export default function Footer() {
    const currentYear = new Date().getFullYear();
    const navRef = useRef(null);
    useCrossAppAnchorTargetAssignment(navRef);
    const linkProps = useContext(NavLinksContext);

    return (
        <div ref={navRef} className={cx('flex justify-center text-on-dark bg-background-black', styles.Footer)}>
            <div className="relative pt-12 pb-24 px-12 w-full md:max-w-[1440px] md:pt-24 md:pb-48 md:px-24">
                <div className="flex mb-4">
                    <div className="hidden lg:block lg:w-52">
                        <a href="/">
                            <img src={logo} alt="FieldLevel" width="184" height="24" />
                        </a>
                    </div>
                    <div className="flex flex-auto flex-col justify-end lg:flex-row xl:justify-around">
                        <NavColumn>
                            <NavHeader>Explore</NavHeader>
                            {!isInsideNativeApp() && (
                                <NavItem href="https://recruiting.fieldlevel.com">Guidance</NavItem>
                            )}
                            <NavItem href="/app/teams" linkProps={linkProps.teamsLinkProps}>
                                Teams
                            </NavItem>
                            <NavItem href="/app/commitments" linkProps={linkProps.commitmentsLinkProps}>
                                Commitments
                            </NavItem>
                            <NavItem href="/app/job-openings" linkProps={linkProps.jobOpeningsLinkProps}>
                                Coaching Jobs
                            </NavItem>
                            <NavItem href="/app/recruiting-needs" linkProps={linkProps.recruitingNeedsLinkProps}>
                                Recruiting Needs
                            </NavItem>
                            <NavItem href="/app/events" linkProps={linkProps.eventsLinkProps}>
                                Events
                            </NavItem>
                            {!isInsideNativeApp && (
                                <NavItem href="/app/account/plans/athletes" linkProps={linkProps.accountPlansLinkProps}>
                                    Pricing
                                </NavItem>
                            )}
                        </NavColumn>
                        {!isInsideNativeApp() && (
                            <NavColumn>
                                <NavHeader>Company</NavHeader>
                                <NavItem href="/about/company">About</NavItem>
                                <NavItem href="/about/press">Press</NavItem>
                                <NavItem href="/about/jobs">Jobs</NavItem>
                                <NavItem href="/contact">Contact</NavItem>
                                <NavItem href="/policies/terms">Legal</NavItem>
                            </NavColumn>
                        )}
                        <NavColumn>
                            <NavHeader>Account</NavHeader>
                            <NavItem href={`/signin?ReturnUrl=${getReturnUrl()}`}>Log In</NavItem>
                            <NavItem href="/join">Sign Up</NavItem>
                            <NavItem href="https://support.fieldlevel.com/en">Help</NavItem>
                        </NavColumn>
                    </div>
                </div>
                {!isInsideNativeApp() && (
                    <>
                        <hr className="text-muted mb-4" />
                        <div className="flex flex-col-reverse lg:flex-row lg:justify-between lg:items-center">
                            <div className="text-body mb-8">© {currentYear} FieldLevel, Inc.</div>
                            <div className="flex text-[18px] mb-8">
                                <div className="mr-4">Visit us on</div>
                                <div className="mr-4">
                                    <a href="https://twitter.com/FieldLevel" aria-label="Visit us on Twitter">
                                        <TwitterMajor fill="#fff" />
                                    </a>
                                </div>
                                <div className="mr-4">
                                    <a href="https://www.instagram.com/fieldlevel/" aria-label="Visit us on Instagram">
                                        <InstagramMajor fill="#fff" />
                                    </a>
                                </div>
                                <div className="mr-4">
                                    <a href="https://www.facebook.com/FieldLevel/" aria-label="Visit us on Facebook">
                                        <FacebookMajor fill="#fff" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        href="https://www.linkedin.com/company/fieldlevel/"
                                        aria-label="Visit us on LinkedIn"
                                    >
                                        <LinkedinMajor fill="#fff" />
                                    </a>
                                </div>
                            </div>
                            <div className="flex mb-8">
                                <div className="mr-4">
                                    <ExternalLink href="https://apps.apple.com/app/apple-store/id470231113?pt=653620&ct=website-nav-footer&mt=8">
                                        <picture>
                                            <source
                                                srcSet={buildSrcSet(appleLogo1xWebp, appleLogo2xWebp)}
                                                type="image/webp"
                                            />
                                            <source
                                                srcSet={buildSrcSet(appleLogo1xPng, appleLogo2xPng)}
                                                type="image/png"
                                            />
                                            <img
                                                width="122"
                                                height="37"
                                                src={appleLogo2xPng}
                                                alt="Download on the App Store"
                                                loading="lazy"
                                            />
                                        </picture>
                                    </ExternalLink>
                                </div>
                                <div>
                                    <ExternalLink href="https://play.google.com/store/apps/details?id=com.fieldlevel&referrer=utm_source%3Dwebsite-nav-footer">
                                        <picture>
                                            <source
                                                srcSet={buildSrcSet(googleLogo1xWebp, googleLogo2xWebp)}
                                                type="image/webp"
                                            />
                                            <source
                                                srcSet={buildSrcSet(googleLogo1xPng, googleLogo2xPng)}
                                                type="image/png"
                                            />
                                            <img
                                                width="122"
                                                height="37"
                                                src={googleLogo2xPng}
                                                alt="Get in on Google Play"
                                                loading="lazy"
                                            />
                                        </picture>
                                    </ExternalLink>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
