import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    ScrollRestoration,
    redirect,
    generatePath
} from 'react-router-dom';

import 'tailwindcss/tailwind.css';
import '@fieldlevel/playbook/dist/index.css';

import ReactRouterRouteToolsProvider from '@appCore/hooks/routeTools/useRouteTools/ReactRouterRouteToolsProvider';

import ReactQueryProvider from '@appPublic/shared/providers/ReactQueryProvider';
import BasicLoadingFallback from '@appPublic/shared/components/BasicLoadingFallback';
import UNSTABLE_ScrollRestoration, {
    ScrollRestorationSignalProvider
} from '@appPublic/shared/components/UNSTABLE_ScrollRestoration';
import { redirectWithNormalizedSearchParams } from '@appPublic/shared/utility/routing';

import RootErrorElement from '@appPublic/routes/_root/RootErrorElement';

import { PATH_PATTERNS as ACCOUNT_PATH_PATTERNS } from '@appPublic/routes/account/plans/shared/constants';
import { PATH_PATTERNS as COMMITMENTS_PATH_PATTERNS } from '@appPublic/routes/commitments/shared/constants';
import { PATH_PATTERNS as EVENTS_PATH_PATTERNS } from '@appPublic/routes/events/shared/constants';
import { PATH_PATTERNS as JOB_OPENINGS_PATH_PATTERNS } from '@appPublic/routes/job-openings/shared/constants';
import { PATH_PATTERNS as PROFILE_PATH_PATTERNS } from '@appPublic/routes/profile/shared/constants';
import { PATH_PATTERNS as RECRUITING_NEEDS_PATH_PATTERNS } from '@appPublic/routes/recruiting-needs/shared/constants';
import { PATH_PATTERNS as TEAMS_PATH_PATTERNS } from '@appPublic/routes/teams/shared/constants';
import { PATH_PATTERNS as LOGIN_PATH_PATTERNS } from '@appPublic/routes/login/shared/constants';
import { PATH_PATTERNS as INVITE_PATH_PATTERNS } from '@appPublic/routes/invite/shared/constants';

import './index.css';

// Lazy load layouts
const LazyLayout = React.lazy(() => import('@appPublic/shared/components/layouts/Layout'));
const LazyNoNavLayout = React.lazy(() => import('@appPublic/shared/components/layouts/NoNavLayout'));

// Lazy load all route containers
const LazyAccountPlans = React.lazy(() => import('./routes/account/plans/AccountPlans/AccountPlans'));
const LazyCommitmentsDetail = React.lazy(() => import('./routes/commitments/CommitmentsDetail/CommitmentsDetail'));
const LazyCommitmentsList = React.lazy(() => import('./routes/commitments/CommitmentsList/CommitmentsList'));
const LazyEventsDetail = React.lazy(() => import('./routes/events/EventsDetail/EventsDetail'));
const LazyEventsList = React.lazy(() => import('./routes/events/EventsList/EventsList'));
const LazyJobOpeningsDetail = React.lazy(() => import('./routes/job-openings/JobOpeningsDetail/JobOpeningsDetail'));
const LazyJobOpeningsList = React.lazy(() => import('./routes/job-openings/JobOpeningsList/JobOpeningsList'));
const LazyProfileDetail = React.lazy(() => import('./routes/profile/ProfileDetail/ProfileDetail'));
const LazyProfileVideosDetail = React.lazy(() =>
    import('@appPublic/routes/profile/videos/ProfileVideosDetail/ProfileVideosDetail')
);
const LazyProfileVideosList = React.lazy(() => import('./routes/profile/videos/ProfileVideosList/ProfileVideosList'));
const LazyRecruitingNeedsDetail = React.lazy(() =>
    import('./routes/recruiting-needs/RecruitingNeedsDetail/RecruitingNeedsDetail')
);
const LazyRecruitingNeedsList = React.lazy(() =>
    import('./routes/recruiting-needs/RecruitingNeedsList/RecruitingNeedsList')
);
const LazyTeamsList = React.lazy(() => import('./routes/teams/TeamsList/TeamsList'));
const LazyLoginOneTimeCode = React.lazy(() => import('./routes/login/OneTimeCode/OneTimeCode'));
const LazyRosterInvite = React.lazy(() => import('./routes/invite/RosterInvite/RosterInvite'));

// Wrap each component with <Suspense />
const [
    Layout,
    NoNavLayout,
    AccountPlans,
    CommitmentsDetail,
    CommitmentsList,
    EventsDetail,
    EventsList,
    JobOpeningsDetail,
    JobOpeningsList,
    ProfileDetail,
    ProfileVideosDetail,
    ProfileVideosList,
    RecruitingNeedsDetail,
    RecruitingNeedsList,
    TeamsList,
    OneTimeCode,
    RosterInvite
] = [
    [LazyLayout, false, true],
    [LazyNoNavLayout, false, true],
    [LazyAccountPlans, false, false],
    [LazyCommitmentsDetail, true, false],
    [LazyCommitmentsList, false, false],
    [LazyEventsDetail, true, false],
    [LazyEventsList, false, false],
    [LazyJobOpeningsDetail, true, false],
    [LazyJobOpeningsList, false, false],
    [LazyProfileDetail, true, false],
    [LazyProfileVideosDetail, true, false],
    [LazyProfileVideosList, false, false],
    [LazyRecruitingNeedsDetail, false, false],
    [LazyRecruitingNeedsList, false, false],
    [LazyTeamsList, false, false],
    [LazyLoginOneTimeCode, false, true],
    [LazyRosterInvite, false, true]
].map(([Component, isRightSideCol, isFullScreen]) => props => (
    <Suspense fallback={<BasicLoadingFallback isRightSideCol={isRightSideCol} isFullScreen={isFullScreen} />}>
        <Component {...props} />
    </Suspense>
));

const App = () => {
    return (
        <ReactRouterRouteToolsProvider>
            <ReactQueryProvider>
                <ScrollRestorationSignalProvider>
                    <Layout>
                        <Outlet />
                        <UNSTABLE_ScrollRestoration />
                    </Layout>
                </ScrollRestorationSignalProvider>
            </ReactQueryProvider>
        </ReactRouterRouteToolsProvider>
    );
};

const NoNavPages = () => {
    return (
        <ReactRouterRouteToolsProvider>
            <ReactQueryProvider>
                <NoNavLayout>
                    <ScrollRestoration />
                    <Outlet />
                </NoNavLayout>
            </ReactQueryProvider>
        </ReactRouterRouteToolsProvider>
    );
};

const router = createBrowserRouter([
    {
        path: '/',
        errorElement: <RootErrorElement />,
        children: [
            {
                element: <NoNavPages />,
                children: [
                    {
                        path: LOGIN_PATH_PATTERNS.login_oneTimeCode,
                        element: <OneTimeCode />
                    },
                    {
                        path: INVITE_PATH_PATTERNS.invite_roster,
                        element: <RosterInvite />
                    }
                ]
            },
            {
                path: 'app/',
                element: <App />,
                children: [
                    {
                        path: ACCOUNT_PATH_PATTERNS.account_plans,
                        element: <></>,
                        loader: () => redirect('/app/account/plans/athletes')
                    },
                    {
                        path: ACCOUNT_PATH_PATTERNS.account_plans_page,
                        element: <AccountPlans />
                    },
                    {
                        path: COMMITMENTS_PATH_PATTERNS.commitmentsDetail,
                        element: <CommitmentsDetail />
                    },
                    {
                        path: COMMITMENTS_PATH_PATTERNS.commitmentsList,
                        element: <CommitmentsList />,
                        loader: redirectWithNormalizedSearchParams({
                            searchParamKeys: [
                                'sportEnum',
                                'athleteState',
                                'athleticAssociation',
                                'conference',
                                'teamState',
                                'year'
                            ]
                        })
                    },
                    {
                        path: EVENTS_PATH_PATTERNS.eventsDetail,
                        element: <EventsDetail />
                    },
                    {
                        path: EVENTS_PATH_PATTERNS.eventsList,
                        element: <EventsList />
                    },
                    {
                        path: JOB_OPENINGS_PATH_PATTERNS.jobOpeningsDetail,
                        element: <JobOpeningsDetail />
                    },
                    {
                        path: JOB_OPENINGS_PATH_PATTERNS.jobOpeningsList,
                        element: <JobOpeningsList />
                    },
                    {
                        path: PROFILE_PATH_PATTERNS.profileDetail,
                        loader: ({ params, request }) => {
                            // The profileDetail route tree includes a "base path" of the form /app/profile/[id]/[sportEnum].
                            // Historically, the 'sportEnum' portion of this route was served in "CapsCase", but we later
                            // moved on to serving that portion in lowercase. This loader is intended to normalize
                            // all url's in the tree by redirecting to the currently supported version.

                            // If sportEnum has already been normalized, just exit
                            if (params.sportEnum === params.sportEnum.toLowerCase()) {
                                return null;
                            }

                            const currentURL = new URL(request.url);

                            // Generate both the current and intended "base paths" for the tree, for simple replacement
                            const currentBasePath = generatePath(PROFILE_PATH_PATTERNS.profileDetail, params);
                            const newBasePath = generatePath(PROFILE_PATH_PATTERNS.profileDetail, {
                                ...params,
                                sportEnum: params.sportEnum.toLowerCase()
                            });
                            const newPathname = currentURL.pathname.replace(currentBasePath, newBasePath);

                            // Switch out the pathname and redirect
                            const newUrl = new URL(currentURL.href);
                            newUrl.pathname = newPathname;
                            return redirect(newUrl.href);
                        },
                        children: [
                            {
                                index: true,
                                element: <ProfileDetail />
                            },
                            {
                                path: PROFILE_PATH_PATTERNS.profile_videosDetail,
                                element: <ProfileVideosDetail />
                            },
                            {
                                path: PROFILE_PATH_PATTERNS.profile_videosList,
                                element: <ProfileVideosList />
                            }
                        ]
                    },
                    {
                        path: RECRUITING_NEEDS_PATH_PATTERNS.recruitingNeedsDetail,
                        element: <RecruitingNeedsDetail />
                    },
                    {
                        path: RECRUITING_NEEDS_PATH_PATTERNS.recruitingNeedsList,
                        element: <RecruitingNeedsList />,
                        loader: redirectWithNormalizedSearchParams({
                            searchParamKeys: ['sportEnum']
                        })
                    },
                    {
                        path: TEAMS_PATH_PATTERNS.teamsList,
                        element: <TeamsList />,
                        loader: redirectWithNormalizedSearchParams({
                            searchParamKeys: ['sportEnum', 'athleticAssociation', 'conference', 'state']
                        })
                    }
                ]
            }
        ]
    }
]);

ReactDOM.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
    document.getElementById('app-public-root')
);
