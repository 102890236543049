import {
    handleBadRequestError,
    handleUnauthorizedError,
    handleForbiddenError,
    handleNotFoundError,
    handleTooManyRequestsError,
    handleServerError,
    handleFailedRequest
} from './errors';

export class HttpError {
    constructor(url, status, message) {
        this.url = url;
        this.status = status;
        this.message = message;
    }
}

const CLIENT_VERSION_HEADER = 'fl-client-version';
const acceptHeaders = { Accept: 'application/json' };
let commonHeaders = Object.assign({}, acceptHeaders);

const setClientVersion = version => {
    let versionHeaders = {};
    if (version) {
        versionHeaders[CLIENT_VERSION_HEADER] = version;
    }
    commonHeaders = Object.assign({}, versionHeaders, acceptHeaders);
};

const makeRequest = (url, options) => {
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(response => {
                resolve(handleResponse(response));
            })
            .catch(error => {
                handleFailedRequest({ url, error });
                reject(error);
            });
    });
};

const removeEmptyProps = function(obj) {
    const newObj = {};

    Object.keys(obj).forEach(key => {
        if (obj[key] != null && typeof obj[key] != 'undefined') {
            newObj[key] = obj[key];
        }
    });

    return newObj;
};

const serializeParams = params => {
    const queryParams = removeEmptyProps(params);
    const paramsArray = [];

    for (let [key, value] of Object.entries(queryParams)) {
        if (Array.isArray(value)) {
            value.forEach(val => {
                paramsArray.push([key, val]);
            });
        } else {
            paramsArray.push([key, value]);
        }
    }

    return paramsArray;
};

const get = (url, params) => {
    const requestOptions = {
        headers: commonHeaders,
        method: 'GET'
    };

    if (params) {
        const searchParams = new URLSearchParams(serializeParams(params)).toString();
        url += `?${searchParams}`;
    }

    return makeRequest(url, requestOptions);
};

const post = (url, body) => {
    const requestOptions = {
        method: 'POST',
        headers: { ...commonHeaders, 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return makeRequest(url, requestOptions);
};

const postFile = (url, body, options) => {
    const formData = new FormData();
    for (const prop in body) {
        if (Object.hasOwnProperty.call(body, prop)) {
            if (body[prop] instanceof Blob && options?.filename) {
                formData.append(prop, body[prop], options?.filename);
            } else {
                formData.append(prop, body[prop]);
            }
        }
    }

    // Sometimes we post files to other domains (like cloudflare) and they throw CORS errors due to our client version header
    if (options?.skipClientHeader) {
        delete commonHeaders[CLIENT_VERSION_HEADER];
    }

    const requestOptions = {
        method: 'POST',
        headers: { ...commonHeaders },
        body: formData
    };

    return makeRequest(url, requestOptions);
};

const put = (url, body) => {
    const requestOptions = {
        method: 'PUT',
        headers: { ...commonHeaders, 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return makeRequest(url, requestOptions);
};

const _delete = (url, body) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...commonHeaders, 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return makeRequest(url, requestOptions);
};

const handleResponse = response => {
    return response.text().then(body => {
        const data = body.length ? JSON.parse(body) : null;
        if (!response.ok) {
            const error = new HttpError(response.url, response.status, data?.message || response.statusText);

            if (response.status === 400) {
                handleBadRequestError({ url: response.url, error: response });
            } else if (response.status === 401) {
                handleUnauthorizedError({ url: response.url, error: response });
            } else if (response.status === 403) {
                handleForbiddenError({ url: response.url, error: response });
            } else if (response.status === 404) {
                handleNotFoundError({ url: response.url, error: response });
            } else if (response.status === 429) {
                handleTooManyRequestsError({ url: response.url, error: response });
            } else if (response.status >= 500) {
                handleServerError({ url: response.url, error: response });
            }

            return Promise.reject(error);
        }

        return data;
    });
};

export const http = {
    get,
    post,
    postFile,
    put,
    delete: _delete,
    setClientVersion
};
