import React from 'react';
import PropTypes from 'prop-types';

const NestedLayoutRightSideCol = ({ absoluteContent, topContent, leftContent, rightContent, columnContainerId }) => (
    <div className="relative md:mt-5 lg:mt-0">
        {absoluteContent}
        {topContent}
        <div className="flex flex-col lg:!grid lg:!grid-cols-12 gap-4" id={columnContainerId}>
            <div className="col-span-8 lg:mb-10">{leftContent}</div>
            <div className="col-span-4 md:mb-10 lg:mb-0">{rightContent}</div>
        </div>
    </div>
);

NestedLayoutRightSideCol.propTypes = {
    absoluteContent: PropTypes.node,
    topContent: PropTypes.node,
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
    columnContainerId: PropTypes.string
};

export default NestedLayoutRightSideCol;
