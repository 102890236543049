import useUrlBuilder from '@appCore/hooks/routeTools/useUrlBuilder';

import { PATH_PATTERNS } from './shared/constants';

/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderService} urlBuilderService */
/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderOptions} urlBuilderOptions */

/**
 * @callback recruitingNeedsDetailBuilder
 * @param {{ pathParams: { id: string }, options?: urlBuilderOptions }} params
 * @returns {urlBuilderService}
 */

/**
 * @callback recruitingNeedsListBuilder
 * @param {{ searchParams?: { sportEnum?: string, query?: string, year?: string, state?: string, level?: string, position?: string, page?: string }, options?: urlBuilderOptions }} [params]
 * @returns {urlBuilderService}
 */

/**
 * @typedef {Object} recruitingNeedsUrlBuilder
 * @property {recruitingNeedsDetailBuilder} recruitingNeedsDetail
 * @property {recruitingNeedsListBuilder} recruitingNeedsList
 */

/** */

const urlBuilderConfig = {
    recruitingNeedsDetail: {
        reactRouterPathPattern: PATH_PATTERNS.recruitingNeedsDetail
    },
    recruitingNeedsList: {
        reactRouterPathPattern: PATH_PATTERNS.recruitingNeedsList,
        searchParamKeys: ['sportEnum', 'query', 'year', 'state', 'level', 'position', 'page']
    }
};

/**
 * @returns {recruitingNeedsUrlBuilder}
 */
export const useRecruitingNeedsUrlBuilder = () => useUrlBuilder(urlBuilderConfig);
