import bootstrap from '@appCore/services/bootstrap';

/**
 *
 * Log a message with the associated log level
 *
 * logInfo(msg, messageOptions);
 * logWarning(msg, messageOptions);
 * logCritical(msg, messageOptions);
 *
 * @param {string} msg - The message that will be loggged
 * @param {Object} messageOptions - Optional configuration object for the log event
 * @param {LogPriorityEnum} messageOptions.Priority - Override the priority of the log event
 * @param {Array.<string>} messageOptions.Tags - Additional tags to attach to the log event
 *
 */
const logInfo = bootstrap.logInfo;
const logWarning = bootstrap.logWarning;
const logCritical = bootstrap.logCritical;

/**
 *
 * Report and log an error with optional additional context
 *
 * reportError(error, context, messageOptions);
 *
 * @param {Error|string} error - An Error or a custom error message to be reported and logged
 * @param {Error|string|Object} context - Any additional context that might be relevent to the report (data object, caught Error, etc)
 * @param {Object} messageOptions - Optional configuration object for the log event
 * @param {LogPriorityEnum} messageOptions.Priority - Override the priority of the log event
 * @param {Array.<string>} messageOptions.Tags - Additional tags to attach to the log event
 *
 */
const reportError = bootstrap.reportError;

const addCurrentLocationToBreadCrumb = bootstrap.addCurrentLocationToBreadCrumb;
const LogPriorityEnum = bootstrap.LogPriorityEnum;

export { logInfo, logWarning, logCritical, reportError, addCurrentLocationToBreadCrumb, LogPriorityEnum };
