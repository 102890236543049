import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const heightClasses = {
    full: 'h-full',
    1: 'h-6',
    2: 'h-12',
    3: 'h-24',
    4: 'h-48',
    5: 'h-96'
};

const widthClasses = {
    full: 'w-full',
    1: 'w-1/6',
    2: 'w-1/3',
    3: 'w-1/2',
    4: 'w-2/3',
    5: 'w-5/6'
};

const THEMES = {
    light: 'light',
    dark: 'dark'
};

const SkeletonBody = ({ height = 1, width = 'full', theme = THEMES.dark }) => {
    const heightClass = heightClasses[height];
    const widthClass = widthClasses[width];

    return (
        <div
            className={cx(
                theme === THEMES.dark ? 'bg-foreground-dark' : 'bg-foreground-base',
                'animate-pulse rounded-md',
                heightClass,
                widthClass
            )}
        />
    );
};

SkeletonBody.propTypes = {
    height: PropTypes.oneOf(['full', 1, 2, 3, 4, 5]),
    width: PropTypes.oneOf(['full', 1, 2, 3, 4, 5]),
    theme: PropTypes.oneOf(Object.values(THEMES))
};

export default SkeletonBody;
