import _ from 'lodash';

import { reportError } from '@appCore/services/logger';

import { createSimplePubSub } from '@appCore/utility/pubSubUtils';

const forbiddenErrorPubSub = createSimplePubSub();
export const subscribeToForbiddenErrors = forbiddenErrorPubSub.subscribe;

const tooManyRequestsErrorPubSub = createSimplePubSub();
export const subscribeToTooManyRequestsErrors = tooManyRequestsErrorPubSub.subscribe;

const isNumeric = value => {
    return _.isNumber(value) || (!_.isEmpty(value) && !_.isNaN(Number(value)));
};

// Takes URL strings and returns a generic path with id parts as a generic token.
// Handles strings that are fully qualified URLs as well as strings that are already just paths
// (ex: https://blah.com/blah/123?foo=bar and /blah/123?foo=bar should both return /blah/[id])
const getGenericRequestUrl = url => {
    let path = '';
    try {
        const rawUrl = new URL(url);
        path = rawUrl.pathname;
    } catch {
        const urlParts = url && url.split('?');
        path = urlParts && urlParts[0];
    }

    // skip trying to clean up already simple URLs and asset URLs
    const pathParts = path && path.split('/');
    if (!pathParts || pathParts.length < 2 || _.includes(path, 'assets')) {
        return path;
    }

    // replace anything that looks like an id with a generic token
    const cleanPathParts = _.map(pathParts, part => {
        if (isNumeric(part) || _.includes(part, '.')) {
            return '[id]';
        }
        return part;
    });

    return cleanPathParts.join('/');
};

export const handleBadRequestError = ({ url, error }) => {
    try {
        const genericUrl = getGenericRequestUrl(url);
        throw new Error(`Bad Request Error - ${genericUrl}`);
    } catch (e) {
        reportError(e, error);
    }
};

export const handleUnauthorizedError = ({ url, error }) => {
    try {
        const genericUrl = getGenericRequestUrl(url);
        throw new Error(`Unauthorized Error - ${genericUrl}`);
    } catch (e) {
        reportError(e, error);
    }

    window.location = '/common/account/ForceSignOutFromRedirect';
};

export const handleForbiddenError = ({ url, error }) => {
    try {
        const genericUrl = getGenericRequestUrl(url);
        throw new Error(`Forbidden Error - ${genericUrl}`);
    } catch (e) {
        reportError(e, error);
    }
    forbiddenErrorPubSub.publish();
};

export const handleNotFoundError = ({ url, error }) => {
    try {
        const genericUrl = getGenericRequestUrl(url);
        throw new Error(`NotFound Error - ${genericUrl}`);
    } catch (e) {
        reportError(e, error);
    }
};

export const handleTooManyRequestsError = ({ url, error }) => {
    try {
        const genericUrl = getGenericRequestUrl(url);
        throw new Error(`Too Many Requests Error - ${genericUrl}`);
    } catch (e) {
        reportError(e, error);
    }

    tooManyRequestsErrorPubSub.publish({ type: parseInt(error.statusText, 10) });
};

export const handleServerError = ({ url, error }) => {
    try {
        const genericUrl = getGenericRequestUrl(url);
        throw new Error(`Server Error - ${genericUrl}`);
    } catch (e) {
        reportError(e, error);
    }
};

export const handleFailedRequest = ({ url, error }) => {
    // the vast majority of these are failed network requests we can't do anything about
    // and fetch makes it impossible to distiguish between network failures and other
    // potential causes of failures for security reasons. not worth the spam to report.
    console.error(error);
};
