import { generatePath } from 'react-router-dom';

/**
 * @typedef {Object} UrlBuilderService
 * @property {string} href
 * @property {Function} go
 * @property {Object} wiredLinkProps
 * @property {string} [wiredLinkProps.url]
 * @property {boolean} [wiredLinkProps.reloadPage]
 * @property {string} [wiredLinkProps.target]
 */

/**
 * @typedef {Object} UrlBuilderOptions
 * @property {Object} [href]
 * @property {Object} [go]
 * @property {'replace'} [go.location]
 * @property {boolean} [go.preventScrollReset]
 * @property {boolean} [go.reloadPage]
 */

export const generateURLBuilderFactory = (
    routingService,
    deriveDefaultLinkProps = service => ({ url: service.href })
) => ({ uiRouterStateName, reactRouterPathPattern, searchParamKeys = [] }) => {
    const builder = ({ pathParams = {}, searchParams = {}, options = { href: undefined, go: undefined } } = {}) => {
        if (process.env.NODE_ENV === 'development' && searchParamKeys !== 'DYNAMIC') {
            Object.keys(searchParams).forEach(key => {
                if (!searchParamKeys.includes(key)) {
                    console.error(
                        `UrlBuilderService: invalid searchParam key '${key}' passed to url builder for ${
                            uiRouterStateName
                                ? 'state name ' + uiRouterStateName
                                : 'path pattern ' + reactRouterPathPattern
                        }. All necessary search param keys should be added to the 'searchParamKeys' array to avoid the creation of erroneous urls`
                    );
                }
            });
        }
        const sharedArgs = { uiRouterStateName, reactRouterPathPattern, pathParams, searchParams };

        const pathService = {
            href: routingService.href({ ...sharedArgs, options: options.href }),
            go: () => routingService.go({ ...sharedArgs, options: options.go })
        };
        pathService.wiredLinkProps = deriveDefaultLinkProps(pathService);
        return pathService;
    };

    return builder;
};

export const applyUrlBuilderFactoryToConfig = ({ config, factory }) =>
    Object.keys(config).reduce((acc, key) => ({ ...acc, [key]: factory(config[key]) }), {});

// Treat undefined, null or empty string as empty values that can be removed for the purposes of searchParams
const isEmptyValue = value => {
    return value === undefined || value === null || value === '';
};

// Simple helper method to generate paths using 'react router path pattern' syntax.
export const reactRouterGetDerivedPath = ({ reactRouterPathPattern, pathParams = {}, searchParams = {} }) => {
    const path = generatePath(reactRouterPathPattern, pathParams);

    const sp = new URLSearchParams();
    Object.keys(searchParams).forEach(key => {
        if (!isEmptyValue(searchParams[key])) {
            if (Array.isArray(searchParams[key])) {
                searchParams[key].forEach(val => sp.append(key, val));
            } else {
                sp.set(key, searchParams[key]);
            }
        }
    });
    const spString = sp.toString();

    return `${path}${spString.length ? '?' + spString : ''}`;
};

// Simple helper method to perform a "hard reload" (new request to the server) of a local path
export const sameDomainPathReload = ({ newPath, replace = false }) => {
    const newLocation = new URL(`${window.location.origin}${newPath}`);
    if (replace) {
        window.location.replace(newLocation);
        return;
    }
    window.location = newLocation;
};
