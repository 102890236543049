import React from 'react';

import FLOnlyLogo from '@appCore/components/FieldLevelBadge/logos/FLOnlyLogo';
import SkeletonBody from '@appCore/components/skeletons/SkeletonBody';

import NestedLayoutRightSideCol from '@appCore/components/layouts/NestedLayoutRightSideCol';
import ContentWrapper from '@appPublic/shared/components/layouts/ContentWrapper';

const FullScreenWrapper = ({ children }) => {
    return (
        <div className="-mt-[100px]">
            <div>{children}</div>
        </div>
    );
};

const LoadingContent = ({ isFullScreen }) => (
    <div className="h-full bg-foreground-base">
        <div className={`h-[100vh] pt-24 md:pt-48 flex justify-center items-start ${!isFullScreen && 'sm:h-[80vh]'}`}>
            <FLOnlyLogo className="w-[75px] h-auto animate-pulse" />
        </div>
    </div>
);

const BasicLoadingFallback = ({ isRightSideCol, isFullScreen }) => (
    <>
        {isFullScreen ? (
            <FullScreenWrapper>
                <LoadingContent isFullScreen />
            </FullScreenWrapper>
        ) : (
            <ContentWrapper>
                {isRightSideCol ? (
                    <NestedLayoutRightSideCol
                        leftContent={<LoadingContent />}
                        rightContent={
                            <div className="h-[250px] lg:h-[400px]">
                                <SkeletonBody height="full" width="full" />
                            </div>
                        }
                    />
                ) : (
                    <LoadingContent />
                )}
            </ContentWrapper>
        )}
    </>
);
export default BasicLoadingFallback;
