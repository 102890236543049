import useUrlBuilder from '@appCore/hooks/routeTools/useUrlBuilder';

import { PATH_PATTERNS } from './shared/constants';

/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderService} urlBuilderService */
/** @typedef {import("@appCore/utility/routingUtils").UrlBuilderOptions} urlBuilderOptions */

/**
 * @callback teamsListBuilder
 * @param {{ searchParams?: { athleticAssociation?: string, conference?: string, sportEnum?: string, state?: string, page?: string }, options?: urlBuilderOptions }} [params]
 * @returns {urlBuilderService}
 */

/**
 * @typedef {Object} teamsUrlBuilder
 * @property {teamsListBuilder} teamsList
 */

/** */
const urlBuilderConfig = {
    teamsList: {
        reactRouterPathPattern: PATH_PATTERNS.teamsList,
        searchParamKeys: ['athleticAssociation', 'conference', 'sportEnum', 'state', 'page']
    }
};

/**
 * @returns {teamsUrlBuilder}
 */
export const useTeamsUrlBuilder = () => useUrlBuilder(urlBuilderConfig);
